.EYE:hover{
      color: #FE5000!important;
      cursor: pointer!important;
  }
  .IconWrapper {
      justify-content:center!important;
      display:flex!important;
      flex-direction:row!important;
      align-items: center!important;
  }
  .EyeWrapper {
      justify-content:center!important;
      display:flex!important;
      flex-direction:row!important;
      align-items: center!important;
      width: 30px;
  }
  .table-responsive {
    overflow-x: hidden!important;
}

.page-item.active .page-link {
    background-color: #000!important;
    border-color: #000 !important;
}

.page-link {
    color: #000 !important;
    font-weight: bold !important;
}

.page-item.active .page-link {
  color: #fff!important;
}

ul.c-sidebar-nav.h-100.ps {
  background: #fff!important;
}

.c-sidebar .c-sidebar-nav-link:hover, .c-sidebar .c-sidebar-nav-dropdown-toggle:hover {
    background: #FE5000!important;
}

.c-sidebar .c-sidebar-minimizer {
  background: #fff!important;
}

.c-sidebar .c-sidebar-brand {
    background: #fff!important;
}

.breadcrumb-item a {
  color: #FE5000!important;
}

.c-sidebar .c-sidebar-nav-link, .c-sidebar .c-sidebar-nav-dropdown-toggle {
    color: #000 !important;
    font-weight: 900 !important;
    font-family: monospace !important;
    background: transparent !important;
}


.c-sidebar .c-sidebar-nav-link .c-sidebar-nav-icon, .c-sidebar .c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
    color: rgba(12, 2, 2, 0.884)  !important;
}

.c-sidebar {
    color: #000  !important;
    background: #fff !important;
}

.c-sidebar .c-sidebar-nav-title {
   color: #000  !important;
}

.c-sidebar .c-sidebar-minimizer {
   background: rgb(235,237,239) !important;
}


.c-header .c-header-nav .c-header-nav-link, .c-header .c-header-nav .c-header-nav-btn {
    color: #000 !important;
    font-weight: 900 !important;
    font-family: monospace !important;
}

.breadcrumb-item a {
    color: #FE5000 !important;
    font-weight: 900 !important;
    font-family: monospace !important;
}

.badge {
    border-radius: 10px !important;
    padding: 3px 7px !important;
    font-weight: 600  !important;
}


.MuiFab-primary {
    background-color: #fff!important;
}

.InBoxIconRight{
    position: fixed;
    bottom: 5%;
    right: 10px;
}
.InBoxIconLeft{
    position: fixed;
    bottom: 5%;
    left: 10px;
}

.slide-pane__overlay.overlay-after-open {
    z-index: 9999 !important;
}


.slide-pane__title-wrapper {
 margin-left: 0 !important;
}

.slide-pane .slide-pane__title {
    max-width: 100%!important;
}

.slide-pane__content {
    padding: 10px 10px !important;
}

.MuiGrid-root .nav-tabs .nav-item {
    width: 50%  !important;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    color: #fff!important;
    background-color: #FE5000 !important;
    border-color: #c4c9d0 #c4c9d0 #ebedef;
}

.nav-item a {
    color:rgba(12, 2, 2, 0.884) !important;
}

.UnreadLists .MuiListItem-gutters {
    padding-left: 3px !important;
    padding-right: 3px !important;
}

html:not([dir=rtl]) .c-callout-danger {
    border-left-color: #FE5000 !important;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active {
    color: #fff!important;
    background-color: #FE5000 !important;
    border-color: #FE5000 !important;
}

.card  .bg-gradient-warning {
    background: #FE5000 !important;
    background: linear-gradient(
45deg
, #f9b115 0%, #FE5000 100%) !important;
    border-color: #FE5000 !important;
}

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////




.btn-outline-dark {
    color: #FE5000 !important;
    border-color: #FE5000 !important;
}

.badge-dark {
    color: #fff !important;
    background-color: #FE5000 !important;
}


.card-body .btn-dark,.modal-footer .btn-dark  {
    color: #fff ;
    background-color: #FE5000 ;
    border-color: #FE5000 ;
}

.btn-dark:hover {
    color: #000 !important;
    background-color: #EBEDEF !important;
    border-color: #FE5000 !important;
}

.btn-outline-dark:hover {
    background-color: #fff !important;
    border-color: #FE5000 !important;
}

.btn-group .btn-selected {
    color: #000 !important;
    background-color: #EBEDEF !important;
}

.swal2-styled.swal2-confirm {
    background-color: #FE5000 !important;
}
.swal2-styled.swal2-cancel {
    background-color: #EBEDEF!important;
    color: #000  !important;
}

.beltCont .btn-outline-secondary{
    color: #000 !important;
    border-color: #000 !important;
}